import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCalendarDays, faRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() public showClear: boolean = false;
  @Output() public change = new EventEmitter<any>();
  @Output() public blur = new EventEmitter<void>();

  public faCalendarDays = faCalendarDays;
  public faRotateLeft = faRotateLeft;

  public value?: NgbDateStruct;

  private onChange: ((value: any) => void) | undefined;
  private onTouched: (() => void) | undefined;

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public triggerOnTouched(): void {
    if (this.onTouched) {
      this.onTouched();
    }
    this.blur.emit();
  }

  public modelChanged(date: any): void {
    if (this.onChange) {
      this.onChange(date);
    }

    this.change.emit(date);
  }

  public clear(): void {
    this.value = undefined;
    this.modelChanged(undefined);
  }
}
