import { enableProdMode, Injectable } from '@angular/core';
import { AuthConfigService, AuthService, TokenMode, UserFactory } from '@equityeng/auth';

export class AppSettings {
  public version?: string;
  public angularProduction: boolean = false;

  public apiUri?: string;
  public authority?: string;
  public redirectUri?: string;
  public postLogoutRedirectUri?: string;
  public silentRedirectUri?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  public settings: AppSettings = new AppSettings();

  public constructor(private authConfig: AuthConfigService, private service: AuthService) {
    this.authConfig.config.authority = 'https://auth.e2g.com';
    this.authConfig.config.clientId = 'auth-portal';
    this.authConfig.config.redirectUri = 'http://localhost:4200/auth-callback';
    this.authConfig.config.defaultUri = '/public/list-licenses';
    this.authConfig.config.postLogoutRedirectUri = 'http://localhost:4200';
    this.authConfig.config.silentRedirectUri = 'http://localhost:4200/assets/auth/html/silent-auth-callback.html';
    this.authConfig.config.scopes = ['openid', 'offline_access', 'e2g.auth-portal-api'];
    this.authConfig.config.requiredProducts = ['auth'];
    this.authConfig.config.tokenMode = TokenMode.AccessToken;
    this.authConfig.config.userFactoryClass = UserFactory;
    this.authConfig.config.enableDebugLogging = false;
    this.authConfig.config.enableAuthorization = false;
    this.authConfig.config.applicationKey = 'auth';
  }

  public updateSettings(appSettings: AppSettings): void {
    Object.keys(appSettings)
      .filter((key) => key in this.authConfig.config)
      .forEach((key) => {
        const appSettingsKey = key as keyof AppSettings;
        (this.authConfig.config as any)[appSettingsKey] = appSettings[appSettingsKey];
      });

    Object.keys(appSettings).forEach((key) => {
      const appSettingsKey = key as keyof AppSettings;
      (this.settings[appSettingsKey] as any) = appSettings[appSettingsKey];
    });

    this.service.setupService();

    this.enableProduction(this.settings);
  }

  private enableProduction(settings: AppSettings): void {
    if (settings.angularProduction.toString().toLowerCase() === 'true') {
      enableProdMode();
    }
  }
}
