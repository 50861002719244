import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { NewPasswordData } from '../../models/new-password-data.model';
import { TicketResult } from '../../models/ticket-result.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {}

  public verifyEmail(token: string): Observable<TicketResult> {
    let params: HttpParams = new HttpParams();
    params = params.set('ticket', token);
    params = params.set('NO_TOKEN', 'true');
    return this.http.get<TicketResult>(`${this.settingsService.settings.apiUri}/api/Auth/VerifyEmail`, { params: params });
  }

  public getUpdatePasswordEmail(token: string): Observable<string> {
    let params: HttpParams = new HttpParams();
    params = params.set('ticket', token);
    params = params.set('NO_TOKEN', 'true');
    return this.http.get<string>(`${this.settingsService.settings.apiUri}/api/Auth/getUpdatePasswordEmail`, {
      params: params
    });
  }

  public UpdatePassword(request: NewPasswordData): Observable<TicketResult> {
    const params: HttpParams = new HttpParams().set('NO_TOKEN', 'true');
    const body = JSON.stringify(request);
    return this.http.post<TicketResult>(`${this.settingsService.settings.apiUri}/api/Auth/UpdatePassword`, body, {
      params: params
    });
  }
}
