import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService, User } from '@equityeng/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GLOBAL_ADMIN } from './admin-module/models/admin-constants';
import { AuthHelper } from './shared-module/auth-helper';

@Injectable({
  providedIn: 'root'
})
export class GlobalAdminGuard implements CanActivate {
  public constructor(private authService: AuthService) {}

  public canActivate(): Observable<boolean> {
    return this.authService.userAuthenticated$.pipe(
      map((user: User) => AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN]))
    );
  }
}
