<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
  <div class="container-fluid">
    <div class="navbar-brand">
      <img class="d-none d-sm-block" src="../assets/images/eec.svg" alt="EEC" width="100" height="48" />
      <img class="d-block d-sm-none" src="../assets/images/eec.svg" alt="EEC" width="83" height="40" />
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      (click)="isNavbarCollapsed = !isNavbarCollapsed"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      id="btn-collapse"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <div
    class="collapse navbar-collapse d-flex-nowrap justify-content-end"
    id="navbarContent"
    [ngbCollapse]="isNavbarCollapsed"
  >
    <div class="primary-menu">
      <ul class="navbar-nav align-items-left ms-2">
        <li *ngIf="isGlobalAdmin" class="nav-item dropdown" ngbDropdown id="menu-reports">
          <a ngbDropdownToggle class="nav-link" role="button" data-toggle="dropdown">OAUTH</a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a routerLink="/oauth/list-clients" class="dropdown-item" ngbDropdownItem>
              <fa-icon [icon]="faPlug" [fixedWidth]="true"></fa-icon>Client Management
            </a>
            <div class="dropdown-divider"></div>
            <a routerLink="/oauth/list-api-resources" class="dropdown-item" ngbDropdownItem>
              <fa-icon [icon]="faKeySkeleton" [fixedWidth]="true"></fa-icon>API Resource Management
            </a>
            <a routerLink="/oauth/list-id-resources" class="dropdown-item" ngbDropdownItem>
              <fa-icon [icon]="faIdCard" [fixedWidth]="true"></fa-icon>ID Resource Management
            </a>
          </div>
        </li>

        <li *ngIf="isGlobalAdmin" class="nav-item dropdown" ngbDropdown id="menu-reports">
          <a ngbDropdownToggle class="nav-link" role="button" data-toggle="dropdown">SSO</a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a routerLink="/saml/list-saml-configs" class="dropdown-item" ngbDropdownItem>
              <fa-icon [icon]="faPuzzlePiece" [fixedWidth]="true"></fa-icon>SAML Config Management
            </a>
            <a routerLink="/admin/list-jit-defaults" class="dropdown-item" ngbDropdownItem>
              <fa-icon [icon]="faBolt" [fixedWidth]="true"></fa-icon>JIT User Defaults
            </a>
          </div>
        </li>
        <li *ngIf="hasAdmin" class="nav-item dropdown" ngbDropdown id="menu-reports">
          <a ngbDropdownToggle class="nav-link" role="button" data-toggle="dropdown">Admin</a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a routerLink="/admin/list-users" class="dropdown-item" ngbDropdownItem *ngIf="canEditUser">
              <fa-icon [icon]="faUserCircle" [fixedWidth]="true"></fa-icon>User Management
            </a>
            <a routerLink="/admin/user-groups" class="dropdown-item" ngbDropdownItem *ngIf="isGlobalAdmin">
              <fa-icon [icon]="faUsers" [fixedWidth]="true"></fa-icon>User Groups
            </a>
            <ng-container *ngIf="canEditCompany">
              <li><hr class="dropdown-divider" /></li>
              <a routerLink="/admin/list-companies" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faBuilding" [fixedWidth]="true"></fa-icon>Company Management
              </a>
              <a routerLink="/admin/list-login-requirements" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faBallotCheck" [fixedWidth]="true"></fa-icon>Login Requirements
              </a>
            </ng-container>
            <ng-container *ngIf="isGlobalAdmin">
              <li><hr class="dropdown-divider" /></li>
              <a routerLink="/admin/products" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faTasks" [fixedWidth]="true"></fa-icon>Products
              </a>
              <a routerLink="/admin/product-features" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faTicketAlt" [fixedWidth]="true"></fa-icon>Product Features
              </a>
              <a routerLink="/admin/list-product-package" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faObjectGroup" [fixedWidth]="true"></fa-icon>Product Packages
              </a>
              <li><hr class="dropdown-divider" /></li>
              <a routerLink="/admin/edit-email" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faEnvelope" [fixedWidth]="true"></fa-icon>Edit Email Templates
              </a>
              <a routerLink="/admin/edit-email-lists" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faListAlt" [fixedWidth]="true"></fa-icon>Edit Email Lists
              </a>
              <a routerLink="/admin/edit-smtp-credentials" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faAddressCard" [fixedWidth]="true"></fa-icon>Edit Email Credentials
              </a>
              <li><hr class="dropdown-divider" /></li>
              <a routerLink="/admin/edit-admin-permissions" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faUnlockAlt" [fixedWidth]="true"></fa-icon>Admin Permissions
              </a>
              <a routerLink="/admin/log" class="dropdown-item" ngbDropdownItem>
                <fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon>View Logs
              </a>
              <li><hr class="dropdown-divider" /></li>
            </ng-container>

            <a *ngIf="canEditTrials" routerLink="/admin/list-trials" class="dropdown-item" ngbDropdownItem>
              <fa-icon [icon]="faClock" [fixedWidth]="true"></fa-icon>Trial Management
            </a>
            <a *ngIf="canEditSubs" routerLink="/admin/list-subscriptions" class="dropdown-item" ngbDropdownItem>
              <fa-icon [icon]="faIdCardAlt" [fixedWidth]="true"></fa-icon>Subscription Management
            </a>
          </div>
        </li>
        <li class="nav-item">
          <a routerLink="/public/list-licenses/" class="nav-link">Manage Licenses</a>
        </li>

        <li class="nav-item dropdown" ngbDropdown id="menu-users">
          <a ngbDropdownToggle class="nav-link" role="button" data-toggle="dropdown">
            <fa-icon [icon]="faUserCircle"></fa-icon>
          </a>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a class="dropdown-header" ngbDropdownItem disabled>
              {{ username }}
            </a>
            <li><hr class="dropdown-divider" /></li>
            <a class="dropdown-header" ngbDropdownItem disabled>
              <strong>Client</strong>&nbsp;Version:&nbsp;{{ clientVersionInfo | async }}
            </a>
            <a class="dropdown-header" ngbDropdownItem disabled>
              <strong>Server</strong>&nbsp;Version:&nbsp;{{ serverVersionInfo | async }}
            </a>
            <li><hr class="dropdown-divider" /></li>
            <a class="dropdown-item pointer" (click)="logout()" id="menu-logout" ngbDropdownItem>
              <fa-icon [icon]="faSignOutAlt" [fixedWidth]="true" class="mr-2"></fa-icon>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
