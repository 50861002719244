export const GLOBAL_ADMIN = 'GA';

export const EDIT_USER = 'EU';
export const DELETE_USER = 'DU';
export const IMPORT_USERS = 'IU';
export const EDIT_COMPANY = 'EC';
export const DELETE_COMPANY = 'DC';
export const VIEW_RECENT_LOGINS = 'VRL';
export const EDIT_TRIAL = 'ET';
export const DELETE_TRIAL = 'DT';
export const EDIT_SUBSCRIPTION = 'ES';
export const ARCHIVE_SUBSCRIPTION = 'AS';

export const REPORT_ACTIVE_USERS_AND_PRODUCTS = 'RAUP';
export const REPORT_ASSIGNED_PRODUCTS = 'RAP';
export const REPORT_COMPANY_USER_ACTIVITY = 'RCUA';
