import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { NgxPaginationModule } from 'ngx-pagination';

import { BlobImgComponent } from './blob-img/blob-img.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DialogHostDirective } from './dialog-host.directive';
import { DialogComponent } from './dialog/dialog.component';
import { GoBackComponent } from './go-back/go-back.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ServerPaginationComponent } from './pagination/server-pagination.component';
import { PasteDirective } from './paste.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    NgbModule,
    NgxPaginationModule,
    AgGridModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [
    PaginationComponent,
    ServerPaginationComponent,
    PasteDirective,
    BlobImgComponent,
    DialogHostDirective,
    DialogComponent,
    DatePickerComponent,
    GoBackComponent
  ],
  exports: [
    CommonModule,
    NgxPaginationModule,
    AgGridModule,
    PaginationComponent,
    ServerPaginationComponent,
    PasteDirective,
    BlobImgComponent,
    DatePickerComponent,
    GoBackComponent
  ]
})
export class SharedModule {}
