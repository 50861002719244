export const addZ = (dateString: string): string => {
  return `${dateString}z`;
};

export const formatDate = (date: Date): string => {
  const dateObj = ensureDateObject(date);
  if (dateObj === undefined) {
    return '';
  }

  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  return `${month}/${day}-${year}`;
};

export const formatDateAmPm = (date: Date): string => {
  const dateObj = ensureDateObject(date);
  if (dateObj === undefined) {
    return '';
  }

  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const time = formatAmPm(dateObj);
  return `${month}/${day}-${year} ${time}`;
};

export const replaceTime = (orgDate: Date, hour: number, minute: number, second: number, milli: number): Date => {
  const datetime = JSON.stringify(offsetByTimezone(orgDate));

  const date = datetime.substring(0, datetime.indexOf('T'));
  const newHour = `${hour}`.padStart(2, '0');
  const newminute = `${minute}`.padStart(2, '0');
  const newsecond = `${second}`.padStart(2, '0');
  const newMilli = `${milli}`.padStart(3, '0');

  const newDate = `${date}T${newHour}:${newminute}:${newsecond}.${newMilli}Z"`;
  return JSON.parse(newDate);
};

export const formatAmPm = (date: Date): string => {
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const offsetByTimezone = (orgDate: Date): Date => {
  const MS_PER_MINUTE = 60000;
  return new Date(orgDate.getTime() - orgDate.getTimezoneOffset() * MS_PER_MINUTE);
};

const ensureDateObject = (date: Date | undefined): Date | undefined => {
  if (date instanceof Date) {
    return date;
  }

  const realDate = dateFromString(date);
  if (realDate !== undefined) {
    return realDate;
  }

  return undefined;
};

const dateFromString = (dateString: string | undefined): Date | undefined => {
  const result = Date.parse(dateString?.replace(/-/g, '/').replace(/T.+/, '') || '');
  if (isNaN(result)) {
    return undefined;
  }

  return new Date(result);
};
