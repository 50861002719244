import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractAuthCallbackComponent, AuthConfigService, AuthService } from '@equityeng/auth';

@Component({
  selector: 'auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent extends AbstractAuthCallbackComponent implements OnInit {
  private captions = ['Completing authentication process...', 'You will be redirected shortly...'];

  public randomCaption: string = '';

  public constructor(authSvc: AuthService, authConfig: AuthConfigService, router: Router) {
    super(authSvc, authConfig, router);
  }

  public ngOnInit(): void {
    this.randomCaption = this.captions[Math.floor(Math.random() * this.captions.length)];

    super.ngOnInit();
  }
}
