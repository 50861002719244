import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { PaginationData } from './pagination-data.model';

@Component({
  selector: 'app-server-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class ServerPaginationComponent implements OnInit, OnChanges {
  @Input() public totalPages: number = 0;
  @Input() public startingPage: number = 1;
  @Output() public pageChanged = new EventEmitter<number>();
  public pages: Array<PaginationData> = new Array<PaginationData>();

  private currentPageInternal = 1;

  public ngOnInit(): void {
    this.currentPageInternal = this.startingPage || 1;
    this.configPages();
  }

  public ngOnChanges(): void {
    this.configPages();
  }

  public prevDisabled(): boolean {
    return this.currentPageInternal <= 1;
  }

  public nextDisabled(): boolean {
    return this.currentPageInternal > this.totalPages - 1;
  }

  public prev(): void {
    if (this.prevDisabled()) {
      return;
    }
    this.currentPageInternal--;
    this.pageChanged.emit(this.currentPageInternal);
    this.updateSelected();
  }

  public next(): void {
    if (this.nextDisabled()) {
      return;
    }
    this.currentPageInternal++;
    this.pageChanged.emit(this.currentPageInternal);
    this.updateSelected();
  }

  public get currentPage(): number {
    return this.currentPageInternal;
  }

  public set currentPage(page: number) {
    this.currentPageInternal = page;
    this.pageChanged.emit(this.currentPageInternal);
    this.updateSelected();
  }

  public silentSetCurrentPage(page: number): void {
    this.currentPageInternal = page;
    this.updateSelected();
  }

  private updateSelected(): void {
    if (this.currentPageInternal < 3) {
      for (const item of this.pages) {
        item.selected = this.currentPageInternal === item.page;
      }
    } else if (this.currentPageInternal > this.totalPages - 2) {
      for (const item of this.pages) {
        item.selected = this.currentPageInternal === item.page;
      }
    }
    this.configPages();
  }

  private configPages(): void {
    this.pages = new Array<PaginationData>();
    let page: number = Math.min(Math.max(1, this.currentPageInternal - 2), Math.max(1, this.totalPages - 4));

    const maxPages = Math.min(5, this.totalPages);
    for (let i = 0; i < maxPages; i++) {
      const selected: boolean = this.currentPageInternal === page;
      this.pages.push(new PaginationData(page++, selected));
    }
  }
}
