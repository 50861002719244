import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, GenericError } from '@equityeng/auth';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.css']
})
export class AuthErrorComponent {
  public error: Observable<GenericError>;

  public constructor(public router: Router, private authService: AuthService) {
    this.error = this.authService.genericError$;
  }

  public retry(): void {
    this.router.navigate(['/']);
  }
}
