<div class="input-group">
  <input class="form-control" readonly="true" [(ngModel)]="value" (ngModelChange)="modelChanged($event)" [matDatepicker]="picker" />
  <button class="btn btn-light" (click)="clear()" type="button" *ngIf="showClear">
    <fa-icon [icon]="faRotateLeft" [fixedWidth]="true"></fa-icon>
  </button>
  <button class="btn btn-light" (click)="picker.open()" type="button">
    <fa-icon [icon]="faCalendarDays" [fixedWidth]="true"></fa-icon>
  </button>
</div>
<mat-datepicker #picker></mat-datepicker>
