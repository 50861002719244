import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appPaste]'
})
export class PasteDirective implements OnInit {
  @Output() public pasteData = new EventEmitter<string>();
  private el: ElementRef;

  public constructor(el: ElementRef) {
    this.el = el;
  }

  public ngOnInit(): void {
    this.el.nativeElement.onpaste = (e: any): boolean => {
      let pastedText;
      const window = this.el.nativeElement.window;
      if (window && window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData('Text');
      } else if (e.clipboardData && e.clipboardData.getData) {
        pastedText = e.clipboardData.getData('text/plain');
      }
      this.pasteData.emit(pastedText);
      return false; // Prevent the default handler from running.
    };
  }
}
