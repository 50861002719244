import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '@equityeng/auth';
import {
  faAddressCard,
  faBallotCheck,
  faBolt,
  faBuilding,
  faClock,
  faEdit,
  faEnvelope,
  faIdCard,
  faIdCardAlt,
  faKeySkeleton,
  faListAlt,
  faLock,
  faMap,
  faObjectGroup,
  faPlug,
  faPuzzlePiece,
  faSignOutAlt,
  faTasks,
  faTicketAlt,
  faUnlockAlt,
  faUpload,
  faUserCircle,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { takeUntil } from 'rxjs/operators';

import {
  EDIT_COMPANY,
  EDIT_SUBSCRIPTION,
  EDIT_TRIAL,
  EDIT_USER,
  GLOBAL_ADMIN,
  IMPORT_USERS,
  REPORT_ACTIVE_USERS_AND_PRODUCTS,
  REPORT_ASSIGNED_PRODUCTS,
  REPORT_COMPANY_USER_ACTIVITY,
  VIEW_RECENT_LOGINS,
} from '../../admin-module/models/admin-constants';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base.component';
import { AuthHelper } from '../../shared-module/auth-helper';
import { VersionDataService } from '../../shared-module/data/version-data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent extends OnDestroyBaseComponent implements OnInit {
  public isNavbarCollapsed = true;

  public isLoggedIn!: boolean;
  public isGlobalAdmin!: boolean;

  public hasAdmin!: boolean;
  public canEditUser!: boolean;
  public canImportUser!: boolean;
  public canViewRecentLogins!: boolean;
  public canEditCompany!: boolean;
  public canEditTrials!: boolean;
  public canEditSubs!: boolean;

  public canRunReports!: boolean;
  public canRunAup!: boolean;
  public canRunAp!: boolean;
  public canRunCua!: boolean;

  public username!: string;

  public clientVersionInfo = this.versionService.clientVersion;
  public serverVersionInfo = this.versionService.serverVersion;

  public faPlug = faPlug;
  public faEdit = faEdit;
  public faBolt = faBolt;
  public faPuzzlePiece = faPuzzlePiece;
  public faUserCircle = faUserCircle;
  public faUsers = faUsers;
  public faUpload = faUpload;
  public faBuilding = faBuilding;
  public faTasks = faTasks;
  public faTicketAlt = faTicketAlt;
  public faMap = faMap;
  public faObjectGroup = faObjectGroup;
  public faEnvelope = faEnvelope;
  public faListAlt = faListAlt;
  public faAddressCard = faAddressCard;
  public faUnlockAlt = faUnlockAlt;
  public faLock = faLock;
  public faClock = faClock;
  public faSignOutAlt = faSignOutAlt;
  public faKeySkeleton = faKeySkeleton;
  public faIdCard = faIdCard;
  public faIdCardAlt = faIdCardAlt;
  public faBallotCheck = faBallotCheck;

  public constructor(private authService: AuthService, private versionService: VersionDataService) {
    super();
  }

  public ngOnInit(): void {
    this.authService.userAuthenticated$.pipe(takeUntil(this.destroy)).subscribe((user: User) => {
      this.isLoggedIn = true;
      this.isGlobalAdmin = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN]);

      this.hasAdmin = AuthHelper.hasAnyPermission(user, [
        GLOBAL_ADMIN,
        EDIT_USER,
        IMPORT_USERS,
        EDIT_COMPANY,
        EDIT_TRIAL,
        EDIT_SUBSCRIPTION
      ]);
      this.canEditUser = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, EDIT_USER]);
      this.canImportUser = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, IMPORT_USERS]);
      this.canViewRecentLogins = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, VIEW_RECENT_LOGINS]);
      this.canEditCompany = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, EDIT_COMPANY]);
      this.canEditTrials = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, EDIT_TRIAL]);
      this.canEditSubs = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, EDIT_SUBSCRIPTION]);

      this.canRunReports = AuthHelper.hasAnyPermission(user, [
        GLOBAL_ADMIN,
        REPORT_ACTIVE_USERS_AND_PRODUCTS,
        REPORT_ASSIGNED_PRODUCTS,
        REPORT_COMPANY_USER_ACTIVITY
      ]);

      this.canRunAup = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, REPORT_ACTIVE_USERS_AND_PRODUCTS]);
      this.canRunAp = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, REPORT_ASSIGNED_PRODUCTS]);
      this.canRunCua = AuthHelper.hasAnyPermission(user, [GLOBAL_ADMIN, REPORT_COMPANY_USER_ACTIVITY]);

      this.username = user.username;
    });
  }

  public logout(): void {
    this.authService.signOut();
  }
}
