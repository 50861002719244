import { Platform } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AuthError, AuthInterceptor, AuthService, GenericError } from '@equityeng/auth';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { merge } from 'rxjs';

import { AppRoutingModule } from './app-routing.module';
import { AppSettingsHttpService } from './app-settings/app-settings-http.service';
import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { FooterComponent } from './home/footer/footer.component';
import { MenuComponent } from './home/menu/menu.component';
import { PageNotFoundComponent } from './home/page-not-found.component';
import { ShellComponent } from './home/shell/shell.component';
import { OnDestroyBaseComponent } from './on-destroy-base-component/on-destroy-base.component';
import { DataService } from './shared-module/data/data.service';
import { SharedModule } from './shared-module/shared.module';
import { formatDate } from './utilities/date-helper';

// uncomment for OIDC client debugging
// import { Log } from 'oidc-client';
// Log.logger=console;
// Log.level=Log.DEBUG;

class AppDateAdapter extends NativeDateAdapter {
  public format(date: Date): string {
    return formatDate(date);
  }
}

export function appInit(settingsHttpService: AppSettingsHttpService) {
  return (): Promise<any> => settingsHttpService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ShellComponent,
    MenuComponent,
    FooterComponent,
    AuthCallbackComponent,
    AuthErrorComponent,
    OnDestroyBaseComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    NgbModule,
    SharedModule
  ],
  providers: [
    DataService,
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [AppSettingsHttpService], multi: true },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500, horizontalPosition: 'center', verticalPosition: 'top' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(private router: Router, private authService: AuthService) {
    this.authService.genericError$.subscribe((error: GenericError) => {
      console.error('Generic error: ', error);
      this.router.navigate(['auth-error']);
    });

    merge(this.authService.authenticationError$, this.authService.authorizationError$).subscribe(
      (authenticationError: AuthError) => {
        if (authenticationError.hasSessionEnded) {
          this.authService.signOut();
        }
      }
    );


  }
}
