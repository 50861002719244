<nav>
  <ul class="pagination">
    <li class="page-item pointer" [class.disabled]="prevDisabled()" (click)="prev()">
      <div class="page-link">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </div>
    </li>
    <li class="page-item pointer" [class.active]="x.selected" *ngFor="let x of pages" (click)="currentPage = x.page">
      <div class="page-link">{{ x.page }}</div>
    </li>
    <li class="page-item pointer" [class.disabled]="nextDisabled()" (click)="next()">
      <div class="page-link">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </div>
    </li>
  </ul>
</nav>
