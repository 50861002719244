import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.css']
})
export class GoBackComponent {
  @Input() public routePath!: string;
  @Input() public pageTitle!: string;

  public faArrowLeft = faArrowLeft;

  public constructor(private router: Router) {}

  public onRouteClick(route: string): void {
    this.router.navigate([route]);
  }
}
