import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@equityeng/auth';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { GlobalAdminGuard } from './global-admin.guard';
import { PageNotFoundComponent } from './home/page-not-found.component';
import { ShellComponent } from './home/shell/shell.component';

const routes: Routes = [
  {
    path: '', component: ShellComponent, canActivate: [AuthGuard], children: [
      { path: 'public', loadChildren: () => import('./public-module/public.module').then(m => m.PublicModule) },
      { path: 'admin', loadChildren: () => import('./admin-module/admin.module').then(m => m.AdminModule)},
      { path: 'reporting', loadChildren: () => import('./report-module/report.module').then(m => m.ReportModule) },
      { path: 'saml', loadChildren: () => import('./saml-module/saml.module').then(m => m.SamlModule), canActivate: [GlobalAdminGuard] },
      { path: 'oauth', loadChildren: () => import('./oauth-module/oauth.module').then(m => m.OauthModule), canActivate: [GlobalAdminGuard] }
    ]
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'auth-error', component: AuthErrorComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
